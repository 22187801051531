import React from 'react';
import PropTypes from 'prop-types';

const BasicInfo = ({ basicinfo }) => {
    const { serves, preptime, cooktime, cleantime } = basicinfo;

    return (
        <ul className="recipe__basicinfo">
            <li className="recipe__basicinfo-item">
                <span className="recipe__basicinfo-title">Servings</span>
                <span itemProp="recipeYield">{serves}</span><span className="u-vh"> portions</span>
            </li>

            <li className="recipe__basicinfo-item">
                <span className="recipe__basicinfo-title">Prep time</span>
                <meta itemProp="prepTime" content={`PT${preptime}M`} />
                {preptime} min<span className="u-vh">ute</span>s
            </li>

            <li className="recipe__basicinfo-item">
                <span className="recipe__basicinfo-title">Cook time</span>
                <meta itemProp="cookTime" content={`PT${cooktime}M`} />
                {cooktime} min<span className="u-vh">ute</span>s
            </li>

            <li className="recipe__basicinfo-item">
                <span className="recipe__basicinfo-title">Clean-up time</span>
                {cleantime} min<span className="u-vh">ute</span>s
            </li>

            <li className="recipe__basicinfo-item">
                <span className="recipe__basicinfo-title">Total time</span>
                <meta itemProp="totalTime" content={`PT${(preptime + cooktime + cleantime)}M`} />
                {(preptime + cooktime + cleantime)} min<span className="u-vh">ute</span>s
            </li>
        </ul>
    );
};

export default BasicInfo;

BasicInfo.propTypes = {
    basicinfo: PropTypes.object,
};
