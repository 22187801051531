import React from 'react';

import { Link } from 'gatsby';

const ingredientSerializers = {
    list: (props) => {
        const { type } = props;
        const bullet = type === 'bullet';

        if (bullet) {
            return (
                <ul className="recipe__list">
                    {props.children}
                </ul>
            );
        }

        return (
            <ol>
                {props.children}
            </ol>
        );
    },
    listItem: (props) => (
        <li className="hello">
            {props.children}
        </li>
    ),
    marks: {
        externalLink: ({ mark, children }) => {
            return (
                <a href={mark.url} className="u-link" target="_blank" rel="noopener noreferrer">
                    {children}
                    <span className="u-vh"> (This link opens in a new window or tab)</span>
                </a>
            );
        },
        internalLink: ({ mark, children }) => {
            const { reference = {} } = mark;
            const href = (reference._type == 'recipe') ? `/recipe/${reference.slug.current}` : `/${reference.slug.current}`;

            return (
                <Link to={href} className="u-link">
                    {children}
                </Link>
            );
        },
    }
};

export default ingredientSerializers;
