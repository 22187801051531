import React from 'react';
import PropTypes from 'prop-types';

import Ratings from './Ratings';

const RecipeRatings = ({ ratings }) => {
    return (
        <ul className="recipe__ratings">
            <li className="recipe__ratings-item">
                <span className="recipe__ratings-title">Tastiness</span>

                <Ratings
                    ratings={ratings}
                    styleName="tasty"
                    output="taste"
                />
            </li>

            <li className="recipe__ratings-item">
                <span className="recipe__ratings-title">Easiness</span>

                <Ratings
                    ratings={ratings}
                    styleName="difficulty"
                    output="ease"
                />
            </li>

            <li className="recipe__ratings-item">
                <span className="recipe__ratings-title">Costliness</span>

                <Ratings
                    ratings={ratings}
                    styleName="cost"
                    output="cost"
                />
            </li>

            <li className="recipe__ratings-item">
                <span className="recipe__ratings-title">Portion size</span>

                <Ratings
                    ratings={ratings}
                    styleName="portion"
                    output="portion"
                />
            </li>

            <li className="recipe__ratings-item">
                <span className="recipe__ratings-title">Overall</span>

                <Ratings
                    ratings={ratings}
                    styleName="stars"
                    output="overall"
                />
            </li>
        </ul>
    );
};

export default RecipeRatings;

RecipeRatings.propTypes = {
    ratings: PropTypes.object,
};
