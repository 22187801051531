import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';

import serializers from '../../utils/serializers';
import faqSerializers from '../../utils/faqSerializers';
import SEO from '../SEO';
import Ratings from './Ratings';
import Category from './Category';
import BasicInfo from './BasicInfo';
import RecipeRatings from './RecipeRatings';
import Ingredients from './Ingredients';
import RecipeExtras from './RecipeExtras';
import Nutrition from './Nutrition';

import './recipe.css';

export default function RecipePage ({ location, data }) {
    const {
        title,
        strapline,
        image,
        nutrition,
        _rawAlert,
        basicinfo,
        categories,
        _rawMethod,
        _rawIngredients,
        extras,
        _rawFaqs,
        ratings,
        cuisine,
        recipetype,
        author,
    } = data.recipe;

    const pagepathname = location.pathname;
    const pageimage = (image) ? image._rawAsset.url : false;

    return (
        <div itemScope itemType="https://schema.org/Recipe">
            <SEO
                title={`Recipe - ${title}`}
                pagepathname={pagepathname}
                image={pageimage}
            />

            <h1 className="u-title recipe__title">
                <span className="recipe__name" itemProp="name">{title}</span>
                <meta itemProp="recipeCategory" content={recipetype} />
                <meta itemProp="recipeCuisine" content={cuisine} />
                {author && <span itemProp="author" hidden>{author}</span>}
            </h1>

            <p className="recipe__strapline" itemProp="description">
                {strapline}
            </p>

            <div className="recipe__meta">
                <Ratings
                    ratings={ratings}
                    styleName="stars"
                    output="overall"
                    isOnlyRatingOnPage={true}
                />

                <ul className="u-category-list recipe__categories">
                    {categories.map((category, index) => (
                        <Category
                            slug={category.slug.current}
                            title={category.title}
                            id={category.id}
                            key={`${category.id}-${index}`}
                            isLink={true}
                        />
                    ))}
                </ul>
            </div>

            {pageimage && <div className="recipe__hero" style={{ backgroundImage: `url(${pageimage})` }}></div>}
            {pageimage && <img src={pageimage} alt="" hidden itemProp="image" />}

            <BasicInfo basicinfo={basicinfo} />
            <RecipeRatings ratings={ratings} />

            <div className="recipe">
                <aside className="recipe__aside">
                    <Ingredients ingredients={_rawIngredients} />

                    {(extras.length > 0) &&
                        <RecipeExtras extras={extras} />
                    }
                </aside>

                <article className="recipe__content">
                    <div className="recipe__method" itemProp="recipeInstructions">
                        <h2 className="u-tertiary-title">
                            Method
                        </h2>

                        <BlockContent blocks={_rawMethod} serializers={serializers} />
                    </div>

                    <Nutrition nutrition={nutrition} />

                    {_rawAlert &&
                        <div className="recipe__alert">
                            <BlockContent blocks={_rawAlert} serializers={serializers} />
                        </div>
                    }

                    {_rawFaqs &&
                        <div className="recipe__faqs">
                            <h2 className="u-tertiary-title">
                                F<span className="u-vh">requently </span>A<span className="u-vh">asked </span>Q<span className="u-vh">uestion</span>s
                            </h2>

                            <BlockContent blocks={_rawFaqs} serializers={faqSerializers} />
                        </div>
                    }
                </article>
            </div>
        </div>
    );
}

export const query = graphql`
    query($slug: String!) {
        recipe: sanityRecipe(slug: {
            current: {
                eq: $slug
            }
        }) {
            id
            image {
                _rawAsset(resolveReferences: {maxDepth: 10})
            }
            title
            slug {
                current
            }
            strapline
            nutrition {
                sugars
                servingsize
                protein
                netcarbs
                fibre
                fat
                carbs
                caloriesfat
                calories
            }
            _rawAlert(resolveReferences: {maxDepth: 10})
            basicinfo {
                serves
                preptime
                cooktime
                cleantime
            }
            categories {
                slug {
                    current
                }
                title
            }
            _rawMethod(resolveReferences: {maxDepth: 10})
            _rawIngredients(resolveReferences: {maxDepth: 10})
            extras {
                _type
                children {
                    text
                    marks
                    _type
                    _key
                }
            }
            _rawFaqs(resolveReferences: {maxDepth: 10})
            ratings {
                taste
                ease
                cost
                portion
            }
            recipetype
            cuisine
            author
        }
    }
`;

RecipePage.propTypes = {
    data: PropTypes.object,
    location: PropTypes.object,
};
