import React from 'react';

import BlockContent from '@sanity/block-content-to-react';

import serializers from './serializers';

const faqSerializers = {
    container: 'dl',
    types: {
        faq: props => {
            const { answer, question } = props.node;

            return (
                <>
                    <dt className="faqs__term">
                        {question}
                    </dt>

                    <dd className="faqs__desc">
                        <BlockContent blocks={answer} serializers={serializers} />
                    </dd>
                </>
            );
        }
    }
};

export default faqSerializers;
