import React from 'react';
import PropTypes from 'prop-types';

const Nutrition = ({ sugars, protein, netcarbs, fibre, fat, carbs, caloriesfat, calories, servingsize }) => {
    if (!sugars || !protein || !netcarbs || !fibre || !fat || !carbs || !caloriesfat || !calories) {
        return null;
    }

    return (
        <div
            className="recipe__nutrition"
            itemProp="nutrition"
            itemScope
            itemType="https://schema.org/NutritionInformation"
        >
            <h2 className="u-tertiary-title recipe__nutrition-title">
                Nutrition
            </h2>

            <p className="recipe__nutrition-servingsize">
                {servingsize}, approx values.
            </p>

            <ul className="recipe__nutrition-list">
                {calories &&
                    <li className="recipe__nutrition-item">
                        <span itemProp="calories">
                            <span className="recipe__nutrition-item-title">Calories</span>
                            {calories} {caloriesfat && <span>({caloriesfat} from fat)</span>}
                        </span>
                    </li>
                }

                {protein &&
                    <li className="recipe__nutrition-item">
                        <span itemProp="proteinContent">
                            <span className="recipe__nutrition-item-title">Protein</span>
                            {protein} g<span className="u-vh">rams</span>
                        </span>
                    </li>
                }

                {fat &&
                    <li className="recipe__nutrition-item">
                        <span itemProp="fatContent">
                            <span className="recipe__nutrition-item-title">Fat</span>
                            {fat} g<span className="u-vh">rams</span>
                        </span>
                    </li>
                }

                {sugars &&
                    <li className="recipe__nutrition-item">
                        <span itemProp="sugarContent">
                            <span className="recipe__nutrition-item-title">Sugars</span>
                            {sugars} g<span className="u-vh">rams</span>
                        </span>
                    </li>
                }

                {fibre &&
                    <li className="recipe__nutrition-item">
                        <span itemProp="fiberContent">
                            <span className="recipe__nutrition-item-title">Fibre</span>
                            {fibre} g<span className="u-vh">rams</span>
                        </span>
                    </li>
                }

                {carbs &&
                    <li className="recipe__nutrition-item">
                        <span itemProp="carbohydrateContent">
                            <span className="recipe__nutrition-item-title">Carb<span className="u-vh">ohydrate</span>s</span>
                            {carbs} g<span className="u-vh">rams</span>
                        </span>
                    </li>
                }

                {netcarbs &&
                    <li className="recipe__nutrition-item">
                        <span className="recipe__nutrition-item-title">Net carb<span className="u-vh">ohydrate</span>s</span>
                        {netcarbs} g<span className="u-vh">rams</span>
                    </li>
                }
            </ul>
        </div>
    );
};

export default Nutrition;

Nutrition.propTypes = {
    sugars: PropTypes.number,
    protein: PropTypes.number,
    netcarbs: PropTypes.number,
    fibre: PropTypes.number,
    fat: PropTypes.number,
    carbs: PropTypes.number,
    caloriesfat: PropTypes.number,
    calories: PropTypes.number,
    servingsize: PropTypes.string,
};
