import React from 'react';
import PropTypes from 'prop-types';

const RecipeExtras = ({ extras }) => {
    return (
        <div className="recipe__extra">
            <h2 className="u-tertiary-title">
                Extras
            </h2>

            <ul className="recipe__list">
                {extras.map((extra, index) => (
                    <li key={index} className="recipe__item">
                        {extra.children[0].text}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default RecipeExtras;

RecipeExtras.propTypes = {
    extras: PropTypes.array,
};

